import axios from 'axios'
import { AwsCognito } from './auth'
export const requestInterceptor = () => {
  axios.interceptors.request.use(
    async function (config: any) {
      let session = await AwsCognito.currentSession()
      if (session) {
        let token = session?.getIdToken()?.getJwtToken()

        if (token) {
          config.headers.Authorization = `Bearer ${token}`
        }
      }
      return config
    },
    function (error) {
      return Promise.reject(error)
    },
  )
}
export const responseInterceptor = () => {
  axios.interceptors.response.use(
    function (response) {
      return response
    },
    function (error) {
      return Promise.reject(error)
    },
  )
}
