import { DefaultSeoProps } from 'next-seo'

const config: DefaultSeoProps = {
  openGraph: {
    title: 'MarketX Ventures',
    type: 'website',
    locale: 'en_US',
    url: 'https://www.marketxventures.com/',
    siteName: 'MarketX Ventures',
    images: [
      {
        url: 'https://www.marketxventures.com/images/open-graph/og-generic-image.jpeg',
        width: 800,
        height: 421,
        alt: 'MarketX Ventures',
        type: 'image/jpeg',
      },
    ],
  },
  twitter: {
    handle: '@marketxventures',
    cardType: 'summary_large_image',
  },
  themeColor: '#6D45B5',
  titleTemplate: '%s | MarketX Ventures',
}

export default config
