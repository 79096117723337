import type { AppProps } from 'next/app'
import '../styles/globals.scss'
import '../styles/homepage.scss'
import 'reflect-metadata'
import { Amplify } from 'aws-amplify'
import { AuthProvider } from '../context/auth'
import Head from 'next/head'
import { requestInterceptor, responseInterceptor } from '../utils/interceptor'
import SEO from '../next-seo.config'
import { DefaultSeo } from 'next-seo'
import { Lato, Montserrat, Inter } from 'next/font/google'

const lato = Lato({
  subsets: ['latin'],
  weight: ['400', '700', '900'],
  display: 'swap',
})

const montserrat = Montserrat({
  subsets: ['latin'],
  display: 'swap',
})

const inter = Inter({
  subsets: ['latin'],
  display: 'swap',
})

Amplify.configure({
  Auth: {
    region: process.env.NEXT_PUBLIC_AWS_AMPLIFY_REGION,
    userPoolId: process.env.NEXT_PUBLIC_AWS_USER_POOL_ID,
    userPoolWebClientId: process.env.NEXT_PUBLIC_AWS_USER_POOL_WEB_CLIENT_ID,
  },
})
requestInterceptor()
responseInterceptor()
function MyApp({ Component, pageProps }: AppProps) {
  return (
    <>
      <style jsx global>{`
        :root {
          --font-heading: ${lato.style.fontFamily};
          --font-body: ${montserrat.style.fontFamily};
          --font-sans: ${inter.style.fontFamily};
        }
      `}</style>
      <Head>
        <link rel="shortcut icon" href="/favicon.png" />
      </Head>
      <AuthProvider>
        <DefaultSeo {...SEO} />
        <Component {...pageProps} />
      </AuthProvider>
    </>
  )
}

export default MyApp
