import React, { createContext, useEffect, useState } from 'react'
import { CognitoUserSession } from 'amazon-cognito-identity-js'
import { Auth } from 'aws-amplify'
import { IUser } from '@types'
import axios from 'axios'
import { compareObject } from '@utils'

export interface AuthObject {
  session: CognitoUserSession | null
  authenticated: boolean
  setUserSession: Function
  user: IUser | null
  getUser: Function
  removeUser: Function
}

const AuthContext = createContext<AuthObject>({
  session: null,
  authenticated: false,
  setUserSession: () => {},
  user: null,
  getUser: () => {},
  removeUser: () => {},
})
const { Provider } = AuthContext

const AuthProvider = ({ children }: { children: any }) => {
  const [session, setSession] = useState<null | CognitoUserSession>(null)
  const [user, setUser] = useState<null | IUser>(null)

  useEffect(() => {
    Auth.currentSession()
      .then(session => {
        setSession(session)
      })
      .catch(err => 'Session retrieval failed due to ' + err)
  }, [])

  const getUser = () => {
    const param = encodeURIComponent(
      session?.getIdToken().payload.email.toString(),
    )
    axios.get(process.env.NEXT_PUBLIC_API_URL + '/me').then(res => {
      if (!compareObject(res.data, user)) {
        setUser(res?.data)
      }
    })
  }

  useEffect(() => {
    if (session && !user) {
      getUser()
    }
  }, [session])

  const setAuthUser = (session: CognitoUserSession | null) => {
    setSession(session)
  }

  // checks if the session is authenticated or not
  const isUserAuthenticated = () => {
    return session !== null
  }

  return (
    <Provider
      value={{
        session,
        setUserSession: (session: CognitoUserSession | null) =>
          setAuthUser(session),
        authenticated: isUserAuthenticated(),
        user,
        getUser: getUser,
        removeUser: () => {
          setUser(null)
        },
      }}>
      {children}
    </Provider>
  )
}

export { AuthContext, AuthProvider }
