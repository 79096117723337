import { IProfile } from './IProfile'

export enum KycStatus {
  IN_REVIEW = 'inReview',
  COMPLETE = 'complete',
  REJECTED = 'rejected',
  RETRY = 'retry',
}

export interface IUser {
  id?: number
  firstName: string
  lastName: string
  email: string
  isActive: boolean
  kycStatus: KycStatus | null
  privateKycComment: string
  publicKycComment: string
  createdAt: Date
  updatedAt: Date
  profiles: IProfile[]
  confirmedAccreditation?: boolean
}
