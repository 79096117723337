import { ICompany, IUser } from '@types'
import axios from 'axios'

export const createHubspotDeal = async (user?: IUser, company?: ICompany) => {
  if (user && company) {
    await axios.get(
      process.env.NEXT_PUBLIC_ZAPIER_CREATE_DEAL_WEBHOOK! +
        encodeURI(
          `?firstName=${user.firstName}&lastName=${user.lastName}&email=${user.email}&company=${company.name}`,
        ),
      {
        transformRequest: (data, headers) => {
          delete headers.common
          return data
        },
      },
    )
  }
}
