import { ImageLoaderProps } from 'next/image'

export const getImageUrl = ({
  src,
  width,
  quality,
}:
  | {
      src: string
      width?: number
      quality?: number
    }
  | ImageLoaderProps) => {
  if (process.env.NEXT_PUBLIC_ENVIRONMENT === 'localhost') return src

  const params = []
  if (width) params.push(`w-${width}`)
  if (quality) params.push(`q-${quality}`)

  const paramsString = params.length && params.join(',')

  const trimmedSrc = [...src.split('/')].pop() as string
  let urlEndpoint = `https://ik.imagekit.io/${process.env.NEXT_PUBLIC_IMAGEKIT_ID}`
  if (urlEndpoint[urlEndpoint.length - 1] === '/')
    urlEndpoint = urlEndpoint.substring(0, urlEndpoint.length - 1)

  return paramsString
    ? `${urlEndpoint}/${trimmedSrc}?tr=${paramsString}`
    : `${urlEndpoint}/${trimmedSrc}`
}
