import mixpanel, { Dict, Query } from 'mixpanel-browser'
import { getItem, setItem } from '@utils'

mixpanel.init(`${process.env.NEXT_PUBLIC_MIXPANEL_API_KEY}`, {
  // Use your project's URL, adding a slug for all Mixpanel requests
  api_host: `/mp`,
  ip: true,
})

const getTrackingId = () => {
  let trackingId = getItem('trackingId')
  if (!trackingId) {
    trackingId = Date.now()
    setItem('trackingId', trackingId)
  }
  return trackingId
}

export const Mixpanel = {
  identify: () => {
    const trackingId = getTrackingId()
    mixpanel.identify(trackingId)
  },
  alias: (id: string) => {
    mixpanel.alias(id)
  },
  track: (name: string, props?: Dict) => {
    const trackingId = getTrackingId()
    mixpanel.track(name, { distinct_id: trackingId, ...props })
  },
  track_links: (query: Query, name: string) => {
    mixpanel.track_links(query, name, {
      referrer: document.referrer,
    })
  },
  people: {
    set: (props: Dict) => {
      mixpanel.people.set(props)
    },
  },
}
