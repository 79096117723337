import moment from 'moment'
import assert from 'assert'
import { IInvestment, IUser, KycStatus } from '@types'
export const intToHex = (nr: number) => {
  return nr.toString(16).padStart(2, '0')
}

export const getRandomString = (bytes: number) => {
  const randomValues = new Uint8Array(bytes)
  window.crypto.getRandomValues(randomValues)
  return Array.from(randomValues).map(intToHex).join('A#-')
}

export const getYoutubeId = (url: string) => {
  const regExp =
    /^.*(youtu\.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/
  const match = url.match(regExp)
  if (match && match[2].length == 11) {
    return match[2]
  } else {
    return false
  }
}

export const abbreviateNumber = (
  value: string | number | undefined,
): string => {
  let numValue = Number(value)
  if (!numValue) return ' --'
  if (numValue < 1e3) return `$${numValue}`
  if (numValue >= 1e3 && numValue < 1e6)
    return `$${+(numValue / 1e3).toFixed(1)}K`
  if (numValue >= 1e6 && numValue < 1e9)
    return `$${+(numValue / 1e6).toFixed(1)}M`
  if (numValue >= 1e9 && numValue < 1e12)
    return `$${+(numValue / 1e9).toFixed(1)}B`
  if (numValue >= 1e12) return `$${+(numValue / 1e12).toFixed(1)}T`
  return ''
}

export const percentageRoundTo = (n: string | number) => {
  const multiplicative = Math.pow(10, 2)
  n = parseFloat((+n * multiplicative).toFixed(11))
  return Math.round(n) / multiplicative + '%'
}

export const slugify = (input?: string) =>
  (input || '')
    .trim()
    .toLowerCase()
    .replace(/[^\w ]+/g, '')
    .replace(/ +/g, '-')

export const encodeQueryParams = (params: any) => {
  const query = []
  for (let param in params)
    if (params[param] !== '')
      query.push(
        encodeURIComponent(param) + '=' + encodeURIComponent(params[param]),
      )
  return query.length > 0 ? query.join('&') : ''
}
export const investmentStatusHandler = (
  investment: IInvestment,
  user: IUser,
) => {
  if (investment.status === 'express_interest') {
    return {
      text: 'Expressed Interest',
      color: 'text-red-500',
    }
  } else if (investment.status === 'committed') {
    return {
      text: 'Committed To Invest',
      color: 'text-red-500',
    }
  } else if (investment.status === 'closing') {
    if (!user?.kycStatus) {
      return {
        text: 'Closing - No Kyc Done',
        color: 'text-yellow-500',
      }
    } else if (user?.kycStatus === KycStatus.IN_REVIEW) {
      return {
        text: 'Closing - KyC in Review',
        color: 'text-yellow-500',
      }
    } else if (user?.kycStatus === KycStatus.REJECTED) {
      return {
        text: 'Closing - KyC Rejected',
        color: 'text-yellow-500',
      }
    } else if (user?.kycStatus === KycStatus.RETRY) {
      return {
        text: 'Closing - KyC Failed, Please Retry',
        color: 'text-yellow-500',
      }
    } else if (user?.kycStatus === KycStatus.COMPLETE) {
      if (
        !investment.isSignedPPM ||
        !investment.isSignedSubscriptionAgreement ||
        !investment.isSignedOperatingAgreement
      ) {
        return {
          text: 'Closing - KyC Failed, Please Retry',
          color: 'text-yellow-500',
        }
      } else if (investment.paymentMarkedAsReceivedOn) {
        return {
          text: "Closing - Pending Investor's Fund Wire",
          color: 'text-yellow-500',
        }
      } else if (!investment.isSignedTaxDocumentWx) {
        return {
          text: 'Closing - Pending Tax Documents Signing',
          color: 'text-yellow-500',
        }
      } else if (!investment.isSignedSubscriptionAgreementByMx) {
        return {
          text: 'Closing - Pending MX Subscription Agreement Countersignature',
          color: 'text-yellow-500',
        }
      } else if (!investment.isSignedOperatingAgreementByMx) {
        return {
          text: 'Closing - Pending MX Operating Agreement Countersignature',
          color: 'text-yellow-500',
        }
      } else
        return {
          text: 'Closing',
          color: 'text-yellow-500',
        }
    }
  } else if (investment.status === 'closed') {
    return {
      text: 'Closed',
      color: 'text-green-500',
    }
  } else if (investment.status === 'finalized') {
    return {
      text: 'Finalized',
      color: 'text-green-500',
    }
  }
}
export const standardDate = (
  date: Date | string,
  hideDay?: boolean | undefined,
  hideYear?: boolean | undefined,
) => {
  if (hideDay) {
    return moment(date).format('MMM YYYY')
  }
  if (hideYear) {
    return moment(date).format('MMM Do')
  }

  return moment(date).format('MMM Do, YYYY')
}

export const compareObject = (obj1: any, obj2?: any) => {
  try {
    assert.deepStrictEqual(obj1, obj2)
    return true
  } catch (err) {
    return false
  }
}
export const calculateTotalInvestmentAmountDue = (
  investmentAmount: Number | undefined,
  managementFee: Number | undefined,
  brokerFee: Number | undefined,
  yearsOfManagementFeeDueUpfront: Number | undefined,
) => {
  const totalPercentFee =
    (managementFee ? Number(managementFee) : 0) +
    (brokerFee ? Number(brokerFee) : 0)
  const totalInvestmentAmountDue =
    Number(investmentAmount) +
    (Number(investmentAmount) *
      Number(totalPercentFee) *
      Number(yearsOfManagementFeeDueUpfront)) /
      100

  return Math.round(totalInvestmentAmountDue)
}

export const calculateTotalInvestmentsHandler = (
  investments: IInvestment[],
) => {
  let total = 0
  investments.forEach(item => {
    total = Number(total) + Number(item.investmentAmount)
  })
  return abbreviateNumber(total)
}
