export const clearStorage = () => {
  window.location.href = '/'
  return localStorage.clear()
}

export const getItem = (key: any) => {
  try {
    if (localStorage.getItem(key)) {
      return JSON.parse(localStorage.getItem(key)!)
    }
    return null
  } catch {
    clearStorage()
    return null
  }
}

export const setItem = (key: any, data: any) => {
  try {
    return localStorage.setItem(key, JSON.stringify(data))
  } catch {
    clearStorage()
    return null
  }
}

export const removeItem = (key: any) => {
  return localStorage.removeItem(key)
}
