import { Auth } from 'aws-amplify'
import { getRandomString } from './utils'

const signUp = (email: string) => {
  const params = {
    username: email,
    password: getRandomString(30),
  }
  return Auth.signUp(params)
}

const signIn = async (email: string) => {
  try {
    return await Auth.signIn(email)
  } catch (err) {
    return undefined
  }
}

const signUpAndSignIn = async (email: string) => {
  await signUp(email)
  return await signIn(email)
}

const confirmSignIn = async (authState: any, code: string) => {
  try {
    return await Auth.sendCustomChallengeAnswer(authState, code)
  } catch (err) {
    return undefined
  }
}

export const signOut = () => {
  return Auth.signOut()
}

export const signInOrSignUp = async (email: string) => {
  try {
    const result = await signIn(email)
    return { created: false, result }
  } catch (err) {
    try {
      await signUp(email)
      const result = await signIn(email)

      return { created: true, result }
    } catch (err) {
      return { created: false, result: undefined }
    }
  }
}

export const confirmSignInOrSignUp = async (
  authState: any,
  email: string,
  code: string,
) => {
  try {
    if (authState.user) {
      return await Auth.confirmSignUp(email, code)
      // return { created: true, result }
    } else {
      return await Auth.sendCustomChallengeAnswer(authState, code)
      // return { created: false, result }
    }
  } catch (err) {
    console.log(err)
  }

  return { created: false, result: undefined }
}

export const currentSession = async () => {
  let session
  try {
    session = await Auth.currentSession()
  } catch (e: any) {
    if ((e as string) !== 'No current user') {
      throw e
    }
  }
  return session
}

export const currentUser = async () => {
  const user = await Auth.currentAuthenticatedUser()
  return user
}

export const currentProfileId = async (
  profileId: string | number | undefined = undefined,
) => {
  const user = await Auth.currentAuthenticatedUser()

  if (profileId) {
    await Auth.updateUserAttributes(user, {
      'custom:profile_id': profileId,
    })
  }

  const currentUserInfo = await Auth.currentUserInfo()
  const currentProfileId = currentUserInfo.attributes['custom:profile_id']
  return currentProfileId
}

export const AwsCognito = {
  currentSession,
  currentUser,

  signInOrSignUp,
  confirmSignInOrSignUp,
  signIn,
  confirmSignIn,
  signUp,
  signOut,
  currentProfileId,
  signUpAndSignIn,
}
